"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var InputTrimDirective = (function () {
    function InputTrimDirective(renderer, elementRef, compositionMode) {
        this.onChange = function (_) { };
        this.onTouched = function () { };
        this._sourceRenderer = renderer;
        this._sourceElementRef = elementRef;
    }
    InputTrimDirective_1 = InputTrimDirective;
    Object.defineProperty(InputTrimDirective.prototype, "_type", {
        get: function () {
            return this._sourceElementRef.nativeElement.type || "text";
        },
        enumerable: true,
        configurable: true
    });
    InputTrimDirective.prototype.onBlur = function (event, value) {
        this.updateValue(event, value.trim());
        this.onTouched();
    };
    InputTrimDirective.prototype.onInput = function (event, value) {
        this.updateValue(event, value);
    };
    InputTrimDirective.prototype.registerOnChange = function (fn) { this.onChange = fn; };
    InputTrimDirective.prototype.registerOnTouched = function (fn) { this.onTouched = fn; };
    InputTrimDirective.prototype.writeValue = function (value) {
        this._value = value === "" ? "" : value || null;
        this._sourceRenderer.setProperty(this._sourceElementRef.nativeElement, "value", this._value);
        if (this._type !== "text") {
            this._sourceRenderer.setAttribute(this._sourceElementRef.nativeElement, "value", this._value);
        }
    };
    InputTrimDirective.prototype.setDisabledState = function (isDisabled) {
        this._sourceRenderer.setProperty(this._sourceElementRef.nativeElement, 'disabled', isDisabled);
    };
    InputTrimDirective.prototype.setCursorPointer = function (cursorPosition, hasTypedSymbol) {
        if (hasTypedSymbol && ["text", "search", "url", "tel", "password"].indexOf(this._type) >= 0) {
            this._sourceElementRef.nativeElement.setSelectionRange(cursorPosition, cursorPosition);
        }
    };
    InputTrimDirective.prototype.updateValue = function (event, value) {
        value = this.trim !== "" && event !== this.trim ? value : value.trim();
        var previous = this._value;
        var cursorPosition = this._sourceElementRef.nativeElement.selectionStart;
        this.writeValue(value);
        if ((this._value || previous) && this._value.trim() !== previous) {
            this.onChange(this._value);
        }
        var hasTypedSymbol = value && previous && value !== previous;
        this.setCursorPointer(cursorPosition, hasTypedSymbol);
    };
    var InputTrimDirective_1;
    __decorate([
        core_1.Input(),
        __metadata("design:type", String)
    ], InputTrimDirective.prototype, "trim", void 0);
    __decorate([
        core_1.HostListener("blur", ["$event.type", "$event.target.value"]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", void 0)
    ], InputTrimDirective.prototype, "onBlur", null);
    __decorate([
        core_1.HostListener("input", ["$event.type", "$event.target.value"]),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", void 0)
    ], InputTrimDirective.prototype, "onInput", null);
    InputTrimDirective = InputTrimDirective_1 = __decorate([
        core_1.Directive({
            selector: "input[trim], textarea[trim]",
            providers: [{ provide: forms_1.NG_VALUE_ACCESSOR, useExisting: InputTrimDirective_1, multi: true }]
        }),
        __param(0, core_1.Inject(core_1.Renderer2)),
        __param(1, core_1.Inject(core_1.ElementRef)),
        __param(2, core_1.Optional()), __param(2, core_1.Inject(forms_1.COMPOSITION_BUFFER_MODE)),
        __metadata("design:paramtypes", [core_1.Renderer2,
            core_1.ElementRef, Boolean])
    ], InputTrimDirective);
    return InputTrimDirective;
}());
exports.InputTrimDirective = InputTrimDirective;
